import React, { Component } from 'react'
import InvertedTextBox from '../components/form-elements/InvertedTextBox';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from "redux"; 
import { withRouter } from 'react-router';
import { showAlert } from '../services/Alert';
import AccessAPI  from '../services/AccessAPI';
import blogtracerLogo from '../assets/images/logo.svg'; 
import userNullPhoto from '../assets/images/user.png'; 
import { motion } from 'framer-motion';

class LoginPage extends Component {
    // CHANGE USERNAME
    changeUsername = async (e) => {
        e.preventDefault();
        const username = document.getElementById("username").value;
        this.props.dispatch({type:"USERNAME", payload:username});

        if (username === "") {
            showAlert("error","Hata! Kullanıcı adı boş bırakılmamalı.", this);
        }
        else if (username.length < 4) {
            showAlert("error","Hata! Eksik karakter ile giriş denemesi başarısız.", this);
        }
        else if (!(/(?![@._A-Z-0-9]).{1}/gi).test(username)) { 
            const req = await AccessAPI('/api/users/is-registered/' + username);
            if (req.success) {
                this.props.dispatch({type:"ENTERED_USERNAME", payload: true});
                this.props.dispatch({type:"USERPHOTO", payload: req.photo});
            }
            else {
                showAlert("error","Kullanıcı bulunamadı.", this);
                this.props.dispatch({type:"ENTERED_USERNAME", payload: false});
            }
        }
        else {
            showAlert("error","Hata! Kullanıcı adı yalnızca harf, sayı, nokta ve çizgilerden oluşmalıdır.", this);
        }
    }

    // UPDATE USERNAME 
    updateUsername = (e) => {
        e.preventDefault();
        this.props.dispatch({type:"USERNAME", payload:""});
        this.props.dispatch({type:"ENTERED_USERNAME", payload: false});
    }
        
    // LOGIN ACTION
    logIn = async (e) => { 
        e.preventDefault();
        const password = document.getElementById("password").value;
    
        if ((password).length < 6) {
            showAlert("error","Hata! Eksik karakter ile giriş denemesi başarısız.", this);
        } 
        else if ( password !== "") {
            const req = await AccessAPI("/api/auth/login",{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                        username: this.props.user.username,
                        password
                })
            });
            if (req.success) {
                var userData = {}
                userData.username = this.props.user.username;
                userData.id = req.id;
                this.props.dispatch({type:"FULLNAME", payload: req.user});
                this.props.dispatch({type:"USERID", payload: req.id});
                this.props.dispatch({type:"LOGGED_IN", payload: true});
                localStorage.setItem('user', JSON.stringify(userData));
                localStorage.setItem('token', req.token);
                showAlert("success","Giriş yapıldı. Hoşgeldin " + req.user + ".", this);
                this.props.history.push('/');
            } else {
                showAlert("error","Hata! Kullanıcı adı ve şifre eşleşmedi.", this);
            }
        }
        else {
            showAlert("error","Hata! Giriş yapmak için şifre girmelisin.", this);
        }
    }   

    // VIEW
    render() {
        const {enteredUsername, username, userPhoto} = this.props.user;
        let activeInputs, activeLinks, activeButton;

        if (username === "" || !enteredUsername) {
            activeInputs = <InvertedTextBox inputID="username" title="Kullanıcı Adı" placeHolder="Kullanıcı Adı veya Email"/>; 
            activeButton = <button className="button-primary" onClick={this.changeUsername}>Devam Et</button>;
            activeLinks = <Link to="/signup" className="link">Kayıt ol</Link>
        } else {
            activeInputs = <div><div className="textbox-inverted"><b>{username}</b>
                <button className="textbox-button" onClick={this.updateUsername}>X</button></div>
                <InvertedTextBox inputID="password" title="Şifre" type="password" placeHolder="Şifre girin"/></div>;
            activeButton = <button className="button-primary" onClick={this.logIn}>Giriş Yap</button>
            activeLinks = <a className="link" href="#changeUsername">Şifremi unuttum</a>
        }

        return (
            <motion.div 
            id="login-form" className="box"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, top: [-10, 10, 8, 10, 0], transition: { delay: 0.1} }}
            exit={{ opacity: 0, transition: { duration: 0, delay: 0 } }}>

                {!enteredUsername ? <img id="form-logo" src={blogtracerLogo} alt=""/>  : <img id="user-photo" src={userPhoto !== null ? userPhoto: userNullPhoto} alt=""/>}
                <br/>
                <form>
                    {activeInputs} 
                    {activeButton}
                    {activeLinks} 
                </form>
            </motion.div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    alert: state.alert
});

export default compose(
    withRouter,
    connect(mapStateToProps))(LoginPage);