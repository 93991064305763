import '../index.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from "redux"; 
import { withRouter } from 'react-router-dom';
import PostCard from '../components/PostCard';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import { showAlert } from '../services/Alert';
import AccessAPI from '../services/AccessAPI';

class HomePage extends Component {
    state = {
        postsPage: 0,
        postsLoaded: false,
        postsPageSize: 0,
        posts: []
    }

    loadPosts = async () => {
        if (!this.state.postsLoaded) {
            if (this.state.postsPageSize >= this.state.posts.length) {
                const pageNo = (this.state.postsPage + 1);
                this.setState({
                    postsPage: pageNo
                });

                const token = localStorage.getItem('token');
                const postRequest = await AccessAPI('/api/' + token +  '/contents/followed-users/' + this.props.user.id + '/' + pageNo + '/');
                if (postRequest.success && postRequest.posts.length > 0) {
                    const posts = [...this.state.posts, ...postRequest.posts];
                    this.setState({
                        posts
                    });
                    
                    const pageSize = (this.state.postsPageSize + 4);
                    this.setState({
                        postsPageSize: pageSize
                    });
                } else {
                    showAlert('success','Tüm içerikler yüklendi!', this);

                    this.setState({
                        postsLoaded: true
                    });
                }
            } else {
                const pageSize = (this.state.postsPageSize) + 4;
                this.setState({
                    postsPageSize: pageSize,
                });
            }
        } 
    }

    componentDidMount() {
        this.loadPosts();
    }

    render() {
        return (
            <motion.div className="content-wrapper"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1} }}
            exit={{ opacity: 0 }}>
                
                <div className="grid-container box">
                    <h3>AKIŞ</h3>
                    <div>
                        {
                            this.state.posts.slice(0, this.state.postsPageSize).map((post, id) => {
                                return <PostCard key={id}
                                    category={post.CategoryName}
                                    subcategory={post.SubcategoryName}
                                    title={post.Title}
                                    creatorId={post.UserId}
                                    creatorPhotoId={post.Uid}
                                    creatorName={post.UserName}
                                    postId={post.Id}
                                    handleClick={() => this.props.history.push('/contents/' + post.Address + '/')}
                                />
                            })
                        }
                        
                    </div>
                    <br/>
                    {
                        !this.state.postsLoaded && this.state.posts.length > 4 ?
                            <button onClick={this.loadPosts} className="button-secondary">Devamını Göster</button> 
                        : null
                    }

                    {
                        this.state.posts.length === 0 ?
                            <span className="no-content">Gösterecek içerik bulamadık.</span>
                            : null
                    }

                </div>  
                <Footer />
            </motion.div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    alert: state.alert
});

export default compose(
    withRouter,
    connect(mapStateToProps))(HomePage);