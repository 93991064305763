import { motion } from 'framer-motion';
import React, { Component } from 'react'
import AccessAPI from '../services/AccessAPI';
import { connect } from 'react-redux';
import { showAlert } from '../services/Alert';
import userNullPhoto from '../assets/images/user.png'; 

class CommentCard extends Component {
    state = {
        userImage: null,
        isUserImageLoaded: false,
        isLiked: false,
        currentLikeState: false,
        deleteCommentAction: false,
        deleted: false,
        time: null,
        likes: 0
    }

    componentDidMount = async () => {
        const token = localStorage.getItem('token');

        // USER IMAGE
        const imageData = await AccessAPI('/api/' + token + '/users/' + this.props.ownerId + '/image');
        if (imageData.success) {
            this.setState({
                userImage: imageData.image,
                isUserImageLoaded: true
            });
        } 

        // UPDATE TIME
        const currentDate = new Date().toLocaleString('tr').split(' ')[0].split('.');
        const date = this.props.time.split('.');
        let data;

        if (currentDate[2] === date[2]) {
            if (parseInt(currentDate[1]) === parseInt(date[1])) {
                if (currentDate[0] === date[0]) {
                    data = 'Bugün';
                } else if ((currentDate[0] - date[0]) === 1) {
                    data = 'Dün';
                } else {
                    data = (currentDate[0] - date[0]) + ' Gün Önce';
                }
            } else {
                data = (currentDate[1] - date[1]) + ' Ay Önce';
            }
        } else {
            data = (currentDate[2] - date[2]) + ' Yıl Önce';
        }

        this.setState({
            time: data
        });

        // GET COMMENT LIKES
        const likeData = await AccessAPI('/api/' + token + '/contents/comments/' + this.props.commentId + '/likes');
        if (likeData.success) {
            this.setState({
                likes: likeData.likes
            });
            likeData.likedUsers.map((comment) => {
                if (comment.UserId === this.props.user.id) {
                    this.setState({
                        isLiked: true,
                        currentLikeState: true
                    });
                }
                return null;
            });
        }
    }

    likeComment = (isLiked) => {
        this.setState({
            isLiked 
        });
    }

    deleteComment = async () => {
        if (!this.state.deleteCommentAction) {
            this.setState({
                deleteCommentAction: true
            });

            showAlert('success', 'Yorum seçildi, sil butonuna tıklayarak işlemi gerçekleştirebilirsiniz.', this);
            
            setTimeout(function(){ 
                this.setState({ 
                    deleteCommentAction: false 
                }); 
            }.bind(this), 10000);
        } else {
            const deleteReq = await AccessAPI('/api/contents/comments/remove',{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    commentId: this.props.commentId
                })
            });
            if (deleteReq.success) {
                this.setState({
                    deleted: true
                });
                showAlert('success', 'Seçili yorum silindi.', this);
            }
        }
    }

    componentWillUnmount = async () => {
        if (this.state.currentLikeState !== this.state.isLiked) {
            let action;
            if (this.state.isLiked) {
                action = 'add';
            } else {
                action = 'remove';
            }

            await AccessAPI('/api/contents/comments/likes/' + action,{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userId: this.props.user.id,
                    commentId: this.props.commentId
                })
            });
        }
    }

    render() {
        const likedUsers = (this.state.isLiked !== this.state.currentLikeState) ?
            (this.state.isLiked) ? ((this.state.likes) +1) : ((this.state.likes) -1)
            : this.state.likes;   
        
        return (
            <div 
            style={
                this.state.deleted ? 
                    {'display': 'none'} 
                : this.state.deleteCommentAction ? {'boxShadow': '0 0 5px rgba(0,50,200,0.8)'}: null 
            } 
            className="comment-card">
            
                <div className="comment-photo">
                    <img alt="" src={this.state.userImage !== "" && this.state.userImage !== null &&  this.state.userImage !== undefined ? this.state.userImage : userNullPhoto}/>
                </div>
                <div className="comment-content">
                    <div className="comment-owner">{this.props.ownerName}</div>
                    <div className="comment-body">{this.props.body}</div>
                    <div className="comment-details">
                        <motion.button 
                        whileHover={{scale: 1.05}} whileTap={{scale: [1.5, 0.9, 1]}}
                        onClick={(isLiked) => this.likeComment(!this.state.isLiked)}
                        className="button-secondary">
                            <i style={this.state.isLiked ? {'color': '#F44336' } : null } className="fas fa-heart"></i> {likedUsers}</motion.button>
                        <button className="button-secondary"><i className="far fa-clock"></i> {this.state.time}</button>
                        { 
                            this.props.ownerId === this.props.user.id ?
                            <motion.button 
                            onClick={this.deleteComment}
                            whileHover={{scale: 1.05}} whileTap={{scale: [1.5, 0.9, 1]}} className="button-secondary">
                                <i className="far fa-trash-alt"></i> Sil</motion.button>
                            : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    alert: state.alert
});

export default connect(mapStateToProps)(CommentCard);