const categoryState  = {    
    categories: [],
    subcategories: []
}

function categoryReducer(state = categoryState, action) {
    switch (action.type) {
      case "CATEGORY":
        return {
          ...state, categories: action.payload
        };
      case "SUBCATEGORY":
        return {
          ...state, subcategories: action.payload
        };
      default:
        return state;
    }
}
export default categoryReducer;