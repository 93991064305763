import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from "redux"; 
import { withRouter } from 'react-router-dom';
import CommentCard from '../components/CommentCard';
import AccessAPI from '../services/AccessAPI';
import { motion } from 'framer-motion';
import { showAlert } from '../services/Alert';

class ContentPage extends Component {
    state = {
        followed: false,
        defaultFollowState: false,
        isLiked: false,
        defaultLikeState: false,
        isSaved: false,
        defaultSavedState: false,
        isCopied: false,
        comments: [],
        data: {}
    }

    componentDidMount = async () => {
        // LOAD CONTENT
        const token = localStorage.getItem('token');
        const req = await AccessAPI('/api/' + token + '/contents/' + this.props.contentId  + '/');
        if (req.success) {
            this.setState({
                data: req.data
            });
        }

        // IS FOLLOWED USER
        const followedReq = await AccessAPI('/api/' + token + '/users/' + this.props.user.id + '/followed-users/');
        if (followedReq.success) {
            followedReq.followedUsers.map((user) => {
                if (user === this.state.data.UserId) {
                    this.setState({
                        followed: true,
                        defaultFollowState: true
                    });
                }
                return null;
            });
        } 

        // IS LIKED
        const likesReq = await AccessAPI('/api/' + token + '/contents/' + this.state.data.Id  + '/likes'); 
        if (likesReq.success) {
            likesReq.likedUsers.map((user) => {
                if (user.UserId === this.props.user.id) {
                    this.setState({
                        isLiked: true,
                        defaultLikeState: true
                    });
                }
                return null;
            })
        }

        // IS SAVED
        const savedReq = await AccessAPI('/api/contents/saved/',{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userId: this.props.user.id
                })
            }); 
        if (savedReq.success && savedReq.data.length !== 0) {
            savedReq.data.map((saved) => {
                if (saved.PostId === this.state.data.Id) {
                    this.setState({
                        isSaved: true,
                        defaultSavedState: true
                    });
                }
                return null;
            })        
        }

        // COMMENTS
        this.updateComments();

        // UPDATE TIME
        this.getCreatedTime();
    }

    updateComments = async () => {
        const token = localStorage.getItem('token');
        // COMMENTS
        const commentsReq = await AccessAPI('/api/' + token + '/contents/' + + this.state.data.Id +'/comments/');
        if (commentsReq.success) {
            this.setState({
                comments: commentsReq.comments
            });
        }
    }

    componentWillUnmount = async () => {
        const userId = this.props.user.id;
        
        // Follow State
        if (this.state.defaultFollowState !== this.state.followed) {
            const followedUserId = this.state.data.UserId;
            if (this.state.followed) {
                await AccessAPI('/api/users/followed-users/create',{
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        userId: userId,
                        followedUserId: followedUserId 
                    })
                });
            } else {
                await AccessAPI('/api/' + localStorage.getItem('token')  + '/users/' + userId + '/followed-users/' + followedUserId,{
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: null
                });
            }
        } 

        // Like State
        if (this.state.defaultLikeState !== this.state.isLiked) {
            let action;
            if (this.state.isLiked) {
                action = 'add';
            } else {
                action = 'remove'
            }
            await AccessAPI('/api/contents/likes/'+ action,{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userId: userId,
                    contentId: this.state.data.Id
                })
            });
        }

        // Saved State
        if (this.state.defaultSavedState !== this.state.isSaved) {
            let action;
            if (this.state.isSaved) {
                action = 'add';
            } else {
                action = 'remove'
            }
            await AccessAPI('/api/contents/saved/'+ action,{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userId: userId,
                    contentId: this.state.data.Id
                })
            });
        }
    }

    changeState = (data, value) => {
        this.setState({ 
            [data]: value 
        });

        if (data === 'isCopied' && value) {
            setTimeout(function(){ 
                this.setState({ 
                    isCopied: false 
                }); 
            }.bind(this), 3000);
        } else if (data === 'followed') {
            if (value) {
                showAlert('success', this.state.data.Name + ' adlı kullanıcı takip edildi. ', this);
            } else {
                showAlert('success', this.state.data.Name + ' adlı kullanıcı artık takip edilmiyor. ', this);
            }
        }
    }

    copyAddress = () => {
        const address = this.state.data.Website + '/' + this.state.data.Address;
        navigator.clipboard.writeText(address);
        showAlert('success', 'İçerik adresi başarıyla kopyalandı.' , this);
    }

    getCreatedTime = () => {
        const createdTime = this.state.data.CreatedAt;
        let data;

        let date = createdTime.slice(0, createdTime.indexOf('T')).split('-');
        let time = createdTime.slice(createdTime.indexOf('T')+1, createdTime.length-8);
        date = [date[2], date[1], date[0]];
        const currentDate = new Date().toLocaleString('tr').split(' ')[0].split('.');

        if (currentDate[2] === date[2]) {
            if (currentDate[1] === date[1]) {
                if (currentDate[0] === date[0]) {
                    data = 'Bugün';
                } else if ((currentDate[0] - date[0]) === 1) {
                    data = 'Dün';
                } else {
                    data = (currentDate[0] - date[0]) + ' Gün Önce';
                }
            } else {
                data = (currentDate[1] - date[1]) + ' Ay Önce';
            }
        } else {
            data = (currentDate[2] - date[2]) + ' Yıl Önce';
        }

        let newDataObject = this.state.data;
        newDataObject.CreatedTime = data;
        newDataObject.CreatedAt = date[0] + '.' + date[1] + '.' + date[2] + ' ' + time;
        this.setState({
            data: newDataObject  
        });
    }

    addComment = async () => {
        let comment = document.getElementById('commentText').value;
        if (comment.length > 1) {
            const contentId = this.state.data.Id; 

            comment = comment.replace("'","''");

            const commentReq = await AccessAPI('/api/contents/comments/add',{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userId: this.props.user.id,
                    contentId,
                    comment
                })
            });
            if (commentReq.success) {
                showAlert('success', 'Yorumunuz başarıyla paylaşıldı.', this);
                document.getElementById('commentText').value = '';
                this.updateComments();    
            }
        } else {
            showAlert('error', 'Hata! Metin kutusu boş bırakılamaz.', this);
        }
    }

    render() {
        const { Title, Body, UserId, Username, Name, UserPhoto, Role, UserBio, Website, Address } = this.state.data;
        const { isLiked, isSaved, isCopied } = this.state;
        const redirectAddress = 'https://' + Website + '/' + Address;
        const redirectUserProfile = '/users/' + Username + '/';
        const isFollowed = this.state.followed;
        let userRole;

        switch (Role) {
            case 'admin':
                userRole = 'Yönetici';
                break;
            case 'content-creator':
                userRole = 'İçerik Üreticisi';
                break;
            case 'guest-user':
                userRole = 'Misafir Kullanıcı';
                break;
            default:
                userRole = 'Kullanıcı';
                break;
        }

        const variants = {
            whileHoverButtons: {
                scale: 1.05
            },
            whileTapButtons: { 
                scale: [1.5, 0.9, 1]
            }
        }

        return (
            <motion.div id="content-page" className="content-wrapper box"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1} }}
            exit={{ opacity: 0 }}>

                <div className="content">
                    <h3>{Title}</h3>
                    <p>{Body}</p>
                    <div className="interactive-buttons">
                        <motion.button whileHover={variants.whileHoverButtons} whileTap={variants.whileTapButtons}
                        onClick={(data, value) => this.changeState('isLiked', !isLiked)} className="button-secondary">
                            <i style={isLiked ? { 'color': '#F44336' } : null } className={ isLiked ? 'fas fa-heart' : 'far fa-heart'}></i>
                        </motion.button>
                        
                        <motion.button whileHover={variants.whileHoverButtons} whileTap={variants.whileTapButtons} 
                        onClick={(data, value) => this.changeState('isSaved', !isSaved)} className="button-secondary">
                            <i style={ isSaved ? { 'color': 'rgba(0,0,0,0.7)' } : null } className={ isSaved ? 'fas fa-bookmark' : 'far fa-bookmark'}></i>
                        </motion.button>
                        
                        <motion.button whileHover={variants.whileHoverButtons} whileTap={variants.whileTapButtons} 
                        onClick={(data, value) => {this.changeState('isCopied', true); this.copyAddress()}} className="button-secondary">
                            <i style={isCopied ? { 'color': '#4CAF50' }: null} className={ isCopied ? 'fas fa-clipboard-check' : 'far fa-clipboard'}></i>
                        </motion.button>
                        
                        <motion.button whileHover={variants.whileHoverButtons} className="button-secondary">
                            <i className="far fa-clock"></i><span>{this.state.data.CreatedTime}</span>
                        </motion.button>
                    </div>
                    
                    <div className="profile-card">
                    <div className="profile-photo">
                        <img style={{"borderColor":"green"}} alt="" src={UserPhoto}/>
                    </div>
                    <div>
                        <span onClick={() => this.props.history.push(redirectUserProfile)} className="userName"><b>{Name}</b></span>
                        <br/>
                        <span>{userRole}</span>
                    </div>

                    {
                        this.props.user.id !== UserId ?
                            isFollowed ? 
                                <motion.button 
                                whileHover={variants.whileHoverButtons}
                                onClick={(data, value) => this.changeState('followed', false)}
                                className="button-secondary">Takip <i className='far fa-check'></i></motion.button>
                                : 
                                <motion.button 
                                whileHover={variants.whileHoverButtons}
                                onClick={(data, value) => this.changeState('followed', true)}
                                className="button-secondary">Takip Et</motion.button>
                        : null
                    }
                    
                    <div className="profile-bio">{UserBio}</div>
                    <div className="content-details">
                        <div>
                            <div><i className="fas fa-heart"></i><br/>{this.state.data.Likes}</div>
                            <div><i className="fas fa-comment"></i><br/>{this.state.comments.length}</div>
                            <div><i className="fas fa-eye"></i><br/>{this.state.data.Views}</div>
                        </div>  
                    </div>
                </div>
                    <button onClick={() => window.open(redirectAddress, '_blank')} className="button-primary">İçeriğe Git</button>
                    
                    
                    
                    <div className="comment">
                        <h4>YORUMLAR</h4>
                        <textarea id="commentText" className="textbox" placeholder="Düşüncelerini paylaşabilirsin."></textarea>
                        <br/>
                        <button onClick={this.addComment} className="button-secondary">Paylaş</button>
                    </div>
                    
                    <div className="comment-wrapper">
                        {
                            this.state.comments.map((comment, id) => {
                                const day = new Date(comment.CreatedAt * 1000);
                                var date = day.getDate()+'.'+(day.getMonth()+1)+'.'+day.getFullYear();

                                return <CommentCard key={id}
                                    commentId={comment.Id}
                                    ownerId={comment.UserId}
                                    ownerName={comment.Name}
                                    body={comment.Body}
                                    time={date.toString()} //{ + ' ' + time.toString()}
                                    likes=""
                                />
                            })
                        }
                    </div>
                </div>
                
            </motion.div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    alert: state.alert
});

export default compose(
    withRouter,
    connect(mapStateToProps))(ContentPage);