import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

// SERVICES
import Alert from './services/Alert';
import AccessAPI from './services/AccessAPI';
import AuthControl from './services/UserService'

// COMPONENTS
import Navigation from './components/Navigation';
import SideNavigation from './components/SideNavigation';

// PAGES
import HomePage from './views/HomePage';
import LoginPage from './views/LoginPage';
import RegisterPage from './views/RegisterPage';
import ProfilePage from './views/ProfilePage';
import ContentPage from './views/ContentPage';
import ContentsPage from './views/ContentsPage';
import FollowingPage from './views/FollowingPage';
import ContentCreationPage from './views/ContentCreationPage';
import { connect } from 'react-redux';

async function controlAuth(props, userData) {
    const req = await AccessAPI("/api/auth/", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
                username: userData.username,
                token: localStorage.getItem('token')
        })
    });
    if (req.success) {
        const userReq = await AccessAPI("/api/" + localStorage.getItem('token') + "/users/" + userData.id);
        if (userReq.success) {
            props.dispatch({type:"FULLNAME", payload: userReq.name});
            props.dispatch({type:"USERID", payload: userData.id});
            props.dispatch({type:"USERNAME", payload: userReq.username});
            props.dispatch({type:"USERPHOTO", payload: userReq.photo});
            props.dispatch({type:"ROLE", payload: userReq.role});
            props.dispatch({type:"LOGGED_IN", payload: true});
            
            const newUserData = {
                username: userReq.username,
                id: userData.id, 
                name: userReq.name,
                photo: userReq.photo,
                role: userReq.role
            };
            localStorage.setItem('user', JSON.stringify(newUserData));
        }
    } else {
        console.log(req.message);
        localStorage.clear();
        props.dispatch({type:"LOGGED_IN", payload: false});
    }
}

function Authorization(props) {
  if (localStorage.getItem("user") !== null && localStorage.getItem("token") !== null) {
    const userData = JSON.parse(localStorage.getItem("user"));
  
    if (props.user.username === '' || !props.user.loggedIn || props.user.username !== userData.username) {
      controlAuth(props, userData);
    }
  } else {
      if (props.user.loggedIn) props.dispatch({type:"LOGGED_IN", payload: false});
      localStorage.clear();
  }  
}

function App(props) {
  const location = useLocation();

  // AUTH
  useEffect(() => {
      // Authenticate
      Authorization(props);
      // eslint-disable-next-line
  },[location.pathname]);

  return (
    <div className="App">
      <Alert/>
      <AnimatePresence>
        <Switch location={location} key={location.key}>
          
          {/* HomeROUTE */}
          <Route exact path="/">
            <AuthControl/>
            <Navigation currentPage="HomePage"/>
            <SideNavigation/>
            <HomePage/>
          </Route>

          {/* Login&RegisterROUTE */}
          <Route path="/login">
            <Navigation currentPage="HomePage"/>
            <LoginPage/>
          </Route>

          <Route path="/signup">
            <Navigation currentPage="HomePage"/>
            <RegisterPage/>
          </Route>

          {/* UserROUTES */}
          <Route exact path="/users/:username" render={(props) => (
            <div>
              <AuthControl/>
              <Navigation currentPage="ProfilePage"/>
              <SideNavigation/>
              <ProfilePage user={props.match.params.username} /> 
            </div>
          )} />

          <Route exact path="/users/:username/liked" render={(props) => (
            <div>
              <AuthControl/>
              <Navigation currentPage="FollowingPage"/>
              <SideNavigation/>
              <ProfilePage menu={1} user={props.match.params.username} /> 
            </div>
          )} />

          
          <Route exact path="/users/:username/saved" render={(props) => (
            <div>
              <AuthControl/>
              <Navigation currentPage="FollowingPage"/>
              <SideNavigation/>
              <ProfilePage menu={2} user={props.match.params.username} /> 
            </div>
          )} />

          
          <Route exact path="/users/:username/statistics" render={(props) => (
            <div>
              <AuthControl/>
              <Navigation currentPage="FollowingPage"/>
              <SideNavigation/>
              <ProfilePage menu={3} user={props.match.params.username} /> 
            </div>
          )} />

          {/* ContentROUTES */}
          <Route path="/contents/create">          
            <AuthControl/>
            <Navigation currentPage="LibraryPage"/>
            <SideNavigation/>
            <ContentCreationPage/> 
          </Route>

          <Route exact path="/contents/:contentId" render={(props) => (
            <div>
              <Navigation currentPage="LibraryPage"/>
              <SideNavigation/>
              <ContentPage contentId={props.match.params.contentId}/>
            </div>
          )} />

          <Route path="/contents">
            <AuthControl/>
            <Navigation currentPage="LibraryPage"/>
            <SideNavigation/>
            <ContentsPage/> 
          </Route>

          {/* FollowingRoute */}
          <Route path="/followed">
            <AuthControl/>
            <Navigation currentPage="FollowingPage"/>
            <SideNavigation/>
            <FollowingPage/> 
          </Route>

        </Switch>
      </AnimatePresence>
    </div>
  );
}

const mapStateToProps = function(state) {
  return {
      user: state.user,
  }
}

export default connect(mapStateToProps)(App);
// https://www.taniarascia.com/react-architecture-directory-structure/