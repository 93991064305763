const contentState  = {    
    latestPosts: []
}

function contentReducer(state = contentState, action) {
    switch (action.type) {
        case "POSTS_ADD":
            return {
            ...state, latestPosts: action.payload
            };
        case "CLEAR_POSTS":
            return {
            ...state, latestPosts: []
            };
        default:
            return state;
    }
}
export default contentReducer;