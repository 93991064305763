
import React from 'react'
import { Redirect } from 'react-router';

export default function AuthControl() {
  if (localStorage.getItem("user") !== null) return false;

  return (
    <Redirect to="/login"/>
  )
  
}

export function Logout() {
  if (localStorage.getItem("user") !== null) {
    localStorage.clear();
  }
}
  