import React, { Component } from 'react'

class SearchBox extends Component {
    render() {
        return (
            <div className="searchbox">
                <input id={this.props.searchBoxId} placeholder="Ara... (Devre Dışı)" onBlur={this.props.handle}/>
            </div>
        )
    }
}
export default SearchBox;