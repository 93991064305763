import React, { Component } from 'react'
import { compose } from "redux"; 
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Footer from '../components/Footer';
import AccessAPI from '../services/AccessAPI';
import { showAlert } from '../services/Alert';

class ContentCreationPage extends Component {
    state = {
        step: 0,
        contentName: '',
        contentDescription: '',
        contentLink: '',
        contentCategory : '',
        contentSubCategory : '',
        contentImage: '',
        contentImageBase64: '',
        maxTags: 5,
        tags: [
        ]
    }

    imageToBase64 = () => {
        const img = document.getElementById('contentPhoto').files[0];
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = () => {
            this.setState({
                contentImageBase64: reader.result 
            });
        };
    }

    setContent = (name,value) => {
        this.setState({
            [name]: value
        })
    }

    createPost = async () => {
        const req = await AccessAPI("/api/contents/create/",{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                    userId: this.props.user.id,
                    title: this.state.contentName,
                    description: this.state.contentDescription,
                    link: this.state.contentLink,
                    subcategory: this.state.contentSubCategory,
                    tags: this.state.tags,
                    image: this.state.contentImageBase64
            })
        });
        if (req.success) {
            setTimeout(function(){ this.props.history.push('/'); }.bind(this), 4000);
            showAlert("success","İşlem başarılı! 5 saniye içerisinde Ana Sayfa'ya yönlendirileceksiniz.", this);
        } else {
            showAlert("error","Hata! Beklenmedik bir hata meydana geldi.", this);
        }
    }

    setStep = async (param) => {
        let allowChange = true;
        let value;

        if (param === "increase") {
            switch (this.state.step) {
                case 0:
                    if (this.state.contentName.trim() === "" || this.state.contentDescription.trim() === "") {
                        showAlert("error","Hata! İçerik başlığı ve açıklaması boş bırakılamaz.", this);
                        allowChange = false;
                    }
                    break;
                case 1: 
                    if (this.state.contentLink.trim() === "") {
                        showAlert("error","Hata! İçerik adresi boş bırakılamaz.", this);
                        allowChange = false;
                    } else {
                        const req = await AccessAPI('/api/' + localStorage.getItem('token') +  '/categories/');
                        if (req.success) {
                            this.props.dispatch({type:"CATEGORY", payload: req.categories});
                        } 
                    }
                    break;
                case 2: 
                    if (document.getElementById("mainCategory").value !== "no-selection") {
                        if (this.state.tags.length !== 0) {
                            this.setContent('contentCategory', document.getElementById("mainCategory").value);
                            if (this.state.contentCategory !== '0') {
                                this.setContent('contentSubCategory', document.getElementById("subCategory").value);
                            }
                        } else {
                            showAlert("error","Hata! En az bir etiket eklemelisiniz.", this);
                            allowChange = false;
                        }
                    } else {
                        showAlert("error","Hata! Kategori seçilmelidir.", this);
                        allowChange = false;
                    }
                    break;
                case 3:
                    if (this.state.contentImage === '') {
                        showAlert("error","Hata! İçerik fotoğrafı seçmelisin.", this);
                        allowChange = false;
                    } else {
                        if (document.getElementById('contentPhoto').files[0].size < 275000) {
                            this.imageToBase64();
                            this.createPost();
                        } else {
                            showAlert("error","Hata! İçerik fotoğrafı 250KB'den küçük olmalıdır. Önerilen boyut: 1080x720 (3:2)", this);
                            allowChange = false;
                        }
                    }
                    break;
                case 4:
                    break;
                default:
                    break;
            }

            value = this.state.step + 1;
        } else if (param === "decrease") {
            value = this.state.step - 1;
        }

        if (allowChange) {
            this.setState({
                step: value
            });
        }
    }

    addTag = () => {
        const value = document.getElementById("tagInput").value;

        if (value !== "") {
            if (this.state.tags.indexOf(value) === -1 ) {
                this.setState({
                    tags: [...this.state.tags, value]
                });
            } else {
                showAlert("error","Hata! Bir etiketi birden fazla kullanamazsın.", this);
            }
        } else {
            showAlert("error","Hata! Etiket boş olmamalı.", this);
        }

        document.getElementById("tagInput").value = "";
    }

    removeTag = (index) => {
        this.setState({
            tags: this.state.tags.filter(function(tag, i) { 
                return i !== index })
        });
    }

    handlePhotoChange = () => {
        this.setContent('contentImage', document.getElementById('contentPhoto').files[0].name);
        document.getElementById('contentPhotoPreview').src = URL.createObjectURL(document.getElementById('contentPhoto').files[0]);
    }

    handleOptionChange = async () => {
        if (document.getElementById("mainCategory").value !== "no-selection" && document.getElementById("mainCategory").value !== "0") {
            const req = await AccessAPI('/api/' + localStorage.getItem('token') + '/subcategories/' + document.getElementById("mainCategory").value);
            if (req.success) {
                this.props.dispatch({type:"SUBCATEGORY", payload: req.categories});
            } 
        } else {
            this.props.dispatch({type:"SUBCATEGORY", payload: []});
            this.setContent('contentSubCategory','0')
        }
    }

    renderSwitch = () => {
        const mainContentInfo = <div className="windows-primary">
            <h4>İçerik Bilgisi</h4>
            <input id="contentName" value={this.state.contentName} onChange={() => this.setContent('contentName', document.getElementById("contentName").value)} className="textbox" type="text" placeholder="İçerik Başlığı"/><br/>
            <textarea id="contentDescription" value={this.state.contentDescription} onChange={() => this.setContent('contentDescription', document.getElementById("contentDescription").value)} className="textbox" type="text" placeholder="İçerik Açıklaması"/>
        </div>;

        const contentAddress = <div className="windows-primary">
            <h4>İçerik Adresi</h4>
            <input id="contentAddress" value={this.state.contentLink} onChange={() => this.setContent('contentLink', document.getElementById("contentAddress").value)} className="textbox" type="text" placeholder="İçerik Adresi"/>
        </div>;
        
        const tagInput = <input id="tagInput" className="textbox" type="text" placeholder="Etiket"/>;
        const tagButton = <button onClick={this.addTag} className="button-secondary">Ekle</button>;
        const categoryAndTags = <div className="windows-primary">
            <h4>Kategori</h4>
            <select onChange={this.handleOptionChange} id="mainCategory" className="textbox">
                <option value="no-selection">Kategori Seç</option>
                { 
                    this.props.category.map((category) =>{
                        return <option key={category.Id} value={category.Id}>{category.Name}</option>;
                    })
                }
                <option value="0">Yeni Kategori Talep Et</option>
            </select>
            {
                this.props.subcategory.length > 0 ? 
                <select id="subCategory" className="textbox">
                    { 
                        this.props.subcategory.map((category) =>{
                            return <option key={category.Id} value={category.Id}>{category.Name}</option>;
                        })
                    }
                </select>
                : null
            }

            <h4>Etiketler</h4>
            {this.state.tags.length !== this.state.maxTags ? tagInput : null }
            {this.state.tags.length !== this.state.maxTags ? tagButton : null }

            <div style={this.state.tags.length !== this.state.maxTags ? {"marginTop":"80px"} : {"marginTop":"20px"}} className="tag-wrapper">
                { this.state.tags.length === 0 ? <div className="tag"><i className="far fa-tags"></i><span>Etiket Yok</span>    </div> : null }
                {
                    this.state.tags.map((tag, i) => {     
                        return <div key={i} className="tag">
                                <i className="far fa-tags"></i><span>{tag}</span><i onClick={(index) => this.removeTag(i)} className="delete far fa-times"></i>
                            </div>;                   
                    })
                }
            </div>
            <div style={this.state.tags.length > 0 ? {"color":"green"} : null } id="tag-control">Etiket Sayısı: {this.state.tags.length}/{this.state.maxTags}</div>
        </div>;

        const contentPhoto = <div className="windows-primary">
            <h4>İçerik Fotoğrafı</h4>
            <div onClick={() => document.getElementById('contentPhoto').click()} className="input-file"><i className="far fa-image"></i> { this.state.contentImage !== ''  ? this.state.contentImage : 'Fotoğraf Seç' } </div>
            <input id="contentPhoto" onChange={this.handlePhotoChange} className="textbox" type="file" accept="image/png, image/gif, image/jpeg" />
            <img alt="" id="contentPhotoPreview" />
        </div>;

        const successMessage = <div className="windows-primary">
            <h4>İçerik Yayınlama Başarılı!</h4>
            <div id="success-message" className="textbox">
                <i className="far fa-check-circle"></i>    
                <p> İçerik yayınlama işlemi başarıyla tamamlandı. Gerekli kontroller sağlandıktan sonra içeriğiniz yayına alınacaktır.</p>
            </div>        
        </div>;

        switch (this.state.step) {
            case 0:
                return mainContentInfo;
            case 1:
                return contentAddress;
            case 2:
                return categoryAndTags;
            case 3:
                return contentPhoto;  
            case 4:
                return successMessage;  
            default:
                return null;
        }
    }

    render() {
        const totalSteps = 3;

        return (
            <div className="content-wrapper">
                <div id="content-creation-page" className="box">
                    <h3>İÇERİK OLUŞTUR { this.state.step < 4 ? '(' + (this.state.step+1) + '/' + (totalSteps+1) + ')' : null}</h3>
                    {/* RENDER ACTIVE WINDOW */}
                    {this.renderSwitch()}
                    
                    {/* BUTTONS */}
                    {
                        this.state.step !== 4 ?  
                            <button className="button-primary" onClick={(param) => this.setStep("increase")}>{this.state.step === totalSteps ? 'Yayınla' : 'İleri'}</button>
                        : null
                    }
                    {
                        this.state.step !== 4 ? (this.state.step > 0 ? 
                        <button className="button-primary" onClick={(param) => this.setStep("decrease")}>Geri</button>
                        : null ) : null 
                    }
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    alert: state.alert,
    category: state.category.categories,
    subcategory: state.category.subcategories
});

export default compose(
    withRouter,
    connect(mapStateToProps))(ContentCreationPage);