import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div className="footer box">
                <p>www.blogtracer.com | Social Content Platform</p>
            </div>
        )
    }
}
