import React, { Component } from 'react'

class InvertedTextBox extends Component {
    constructor(props) {
        super(props);

        this.parentRef = React.createRef();
        this.spanRef = React.createRef();
        this.inputRef = React.createRef();
    }

    showInput = () => {
        this.parentRef.current.style.height = '46px';
        this.parentRef.current.style.borderColor = 'var(--primary-color)';

        this.spanRef.current.style.transform = 'translateY(-5px)';
        this.spanRef.current.style.fontSize = '0.8em';
        this.spanRef.current.style.fontWeight = 'bold';

        this.inputRef.current.style.opacity = '1';
        this.inputRef.current.focus();
    }

    hideInput = () => {
        this.parentRef.current.style.borderColor = 'rgba(0, 0, 0, 0.3)';

        if ((this.inputRef.current.value).trim() === '') {
            this.resetInput();
        }
    }

    resetInput = () => {
        this.parentRef.current.style.height = '35px';

        this.spanRef.current.style.transform = 'translateY(0)';
        this.spanRef.current.style.fontSize = '0.85em';
        this.spanRef.current.style.fontWeight = 'normal';

        this.inputRef.current.style.opacity = '0';
    }
    
    componentDidMount = () => {
        this.resetInput();
    }
      
    render() {
        const {inputID, title, type, placeHolder} = this.props; 

        return (        
            <div ref={this.parentRef} className="textbox-inverted" onClick={this.showInput}>
                <span ref={this.spanRef} >{title}</span><br/>
                <input ref={this.inputRef} id={inputID} type={type} placeholder={placeHolder} onFocus={this.showInput} onBlur={this.hideInput} />  
            </div>
        )
    }
    
}

InvertedTextBox.defaultProps = {
    inputID: null,
    type: "text",
    placeHolder: ""
}

export default InvertedTextBox;