const userState  = {    
    username: '',
    fullName: '',
    id: '',
    role: '',
    userPhoto: '',
    enteredUsername: false,
    loggedIn: false
}

function userReducer(state = userState, action) {
    switch (action.type) {
      case "USERNAME":
        return {
          ...state, username: action.payload
        };
      case "USERID":
        return {
          ...state, id: action.payload
        };
      case "FULLNAME":
        return {
          ...state, fullName: action.payload
        };
      case "USERPHOTO":
        return {
          ...state, userPhoto: action.payload
        };
      case "ROLE":
        return {
          ...state, role: action.payload
        };
      case "ENTERED_USERNAME":
        return {
          ...state, enteredUsername: action.payload
        };
      case "LOGGED_IN":
          return {
            ...state, loggedIn: action.payload
          };
      default:
        return state;
    }
}
export default userReducer;