import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from "redux"; 
import { withRouter } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from '../components/Footer';
import AccessAPI from '../services/AccessAPI';

class FollowingPage extends Component {
    state = {
        followedUsers: []
    }

    loadFollows = async () => {
        const token = localStorage.getItem('token');
        
        const followedReq = await AccessAPI('/api/' + token + '/users/' + this.props.user.id + '/followed-users/');
        if (followedReq.success) {
            await Promise.all(
                followedReq.followedUsers.map(async (user) => {
                    const userReq = await AccessAPI('/api/' + token + '/users/' + user );
                    if (userReq.success) {
                        const object = {
                            name: userReq.name,
                            username: userReq.username,
                            profile: userReq.profile,
                            photo: userReq.photo
                        }
                        
                        this.setState({
                            followedUsers: [...this.state.followedUsers, object]
                        });
                    }
                })
            );

        }
    }

    redirectToProfile = (username) => this.props.history.push('/users/' + username + '/');

    componentDidMount = () => {
        this.loadFollows();
    }

    render() {
        return (
            <motion.div id="following-page" className="content-wrapper"initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1} }}
            exit={{ opacity: 0 }}>

                <div id="user-list" className="box">
                   <h3>TAKİP EDİLENLER</h3>
                   <table>
                       <tbody>
                            <tr><th></th><th>Ad</th><th style={{'width': '50%'}}>Hakkında</th><th></th></tr>
                            {
                                this.state.followedUsers.map((user, id) => {
                                    return <tr key={id} onClick={(username) => this.redirectToProfile(user.username)}>
                                        <td><img alt={user.name} src={user.photo}/></td>
                                        <td>{user.name}</td><td>{user.profile}</td>
                                        <td><button className="button-secondary">Profile Git</button></td></tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <Footer />
            </motion.div>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.user,
    alert: state.alert
});

export default compose(
    withRouter,
    connect(mapStateToProps))(FollowingPage);