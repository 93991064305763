import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from "redux"; 
import PostCard from '../components/PostCard';
import Footer from '../components/Footer';
import AccessAPI  from '../services/AccessAPI';
import { showAlert }  from '../services/Alert';
import { withRouter } from 'react-router-dom';
import { motion } from 'framer-motion';

class ContentsPage extends Component {
    state = {
        activeFilter: null,
        activeCategory: null,
        latestPostsPage: 0,
        latestPostsLoaded: false,
        latestPostsPageSize: 0,
        latestPosts: [],
        popularPostsPage: 0,
        popularPostsLoaded: false,
        popularPostsPageSize: 0,
        popularPosts: []
    }

    loadCategoryData = async () => {
        const token = localStorage.getItem('token');
        const categoryRequest = await AccessAPI('/api/' + token +'/categories/');
        if (categoryRequest.success) {
            this.props.dispatch({type:"CATEGORY", payload: categoryRequest.categories});
        }
    }

    changeActiveCategory = (categoryId) => {
        if (this.state.activeCategory === null || this.state.activeCategory !== categoryId) {
            this.setState({
                activeCategory: categoryId
            })
        } else {
            this.setState({
                activeCategory: null
            })
        }
    }

    loadLatestPosts = async () => {
        if (!this.state.latestPostsLoaded) {
            if (this.state.latestPostsPageSize >= this.state.latestPosts.length) {
                const pageNo = (this.state.latestPostsPage + 1);
                this.setState({
                    latestPostsPage: pageNo
                });

                const token = localStorage.getItem('token');
                const latestPostRequest = await AccessAPI('/api/' + token +  '/contents/latest-posts/' + pageNo + '/');
                if (latestPostRequest.success && latestPostRequest.posts.length > 0) {
                    
                    const posts = [...this.state.latestPosts, ...latestPostRequest.posts];
                    this.setState({
                        latestPosts: posts
                    });
                    
                    const pageSize = (this.state.latestPostsPageSize + 4);
                    this.setState({
                        latestPostsPageSize: pageSize
                    });
                } else {
                    showAlert('success','Tüm içerikler yüklendi!', this);

                    this.setState({
                        latestPostsLoaded: true
                    });
                }
            } else {
                const pageSize = (this.state.latestPostsPageSize) + 4;

                this.setState({
                    latestPostsPageSize: pageSize,
                    activeFilter: 'latest-posts'
                });
            }
        } 
    } 

    loadPopularPosts = async () => {
        if (!this.state.popularPostsLoaded) {
            if (this.state.popularPostsPageSize >= this.state.popularPosts.length) {
                const pageNo = (this.state.popularPostsPage + 1);
                this.setState({
                    popularPostsPage: pageNo
                });

                const token = localStorage.getItem('token');
                const popularPostRequest = await AccessAPI('/api/' + token +  '/contents/popular-posts/' + pageNo + '/');
                if (popularPostRequest.success && popularPostRequest.posts.length > 0) {
                    
                    const posts = [...this.state.popularPosts, ...popularPostRequest.posts];
                    this.setState({
                        popularPosts: posts
                    });
                    
                    const pageSize = (this.state.popularPostsPageSize + 4);
                    this.setState({
                        popularPostsPageSize: pageSize
                    });
                } else {
                    showAlert('success','Tüm içerikler yüklendi!', this);

                    this.setState({
                        popularPostsLoaded: true
                    });
                }
            } else {
                const pageSize = (this.state.popularPostsPageSize) + 4;

                this.setState({
                    popularPostsPageSize: pageSize,
                    activeFilter: 'popular-posts'
                });
            }
        } 
    } 
    
    componentDidMount = async () => {
        this.loadCategoryData();
        this.loadLatestPosts();
        this.loadPopularPosts();

        this.setState({
            activeFilter: null
        });
    }

    clearFilter = (e) => {
        const targetFilter = e.target.id.split('-')[0];
        const filterPageSize = targetFilter  + 'PostsPageSize';
        const filterPosts = targetFilter + 'Posts'; 
        const minPageSize = 4;

        if (this.state.activeFilter === null) {
            this.setState({
                [filterPageSize]: this.state[filterPosts].length,
                activeFilter: targetFilter + '-posts'
            });
            e.target.innerText = 'Geri Dön';
        } else {
            this.setState({
                [filterPageSize]: minPageSize,
                activeFilter: null
            });
            e.target.innerText = 'Tümünü Görüntüle';
        }
    }
        
    getActiveCategoryName = () => {
        // this.props.category.map((category) => {
        //     if (category.Id === this.state.activeCategory) {
        //         // return 'category.Name';
        //     }
        // });
        return 'sdad';
    }

    render() {
        let activeFilter;
        switch (this.state.activeFilter) {
            case 'latest-posts':
                activeFilter = 'EN YENİ İÇERİKLER';
                break;
            case 'popular-posts':
                activeFilter = 'EN POPÜLER İÇERİKLER';
                break;  
            default:
                activeFilter = null;
                break;
        }
        return (
            <motion.div id="contents-page" className="content-wrapper" 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1} }}
            exit={{ opacity: 0 }}>

                <div id="categories" className="box">
                    <h3>KATEGORİLER 
                     {this.state.activeFilter !== null ? <button onClick={this.clearFilter} className="filter button-secondary"> {activeFilter} <i className="delete far fa-times"></i></button> : null}
                     {this.state.activeCategory !== null ? <button onClick={(categoryId) => this.changeActiveCategory(this.state.activeCategory)} className="filter button-secondary"> {this.props.category.filter(category => category.Id === this.state.activeCategory).map(category => (category.Name))} <i className="delete far fa-times"></i></button> : null}
                    </h3>
                    <div className="category-container">
                        {
                            this.props.category.map((category) => {
                                const divStyle = {backgroundImage: category.Image}
                                return <div onClick={(categoryId) => this.changeActiveCategory(category.Id)} key={category.Id} style={divStyle} className="category-item">
                                    <i className={category.Icon}></i><span>{category.Name.toUpperCase()}</span>
                                </div>
                            })
                        }
                    </div>
                </div>

                <div id="content-list" className="box">
                    {
                        this.state.activeFilter === null || this.state.activeFilter === 'latest-posts' ?
                            <h3>EN YENİ İÇERİKLER</h3>
                        : null
                    }

                    {
                        this.state.activeFilter === null || this.state.activeFilter === 'latest-posts' ?
                        <div id="latest-posts" className="grid-container">
                            <div>
                                {
                                    this.state.latestPosts.slice(0, this.state.latestPostsPageSize).map((post, id) => {
                                        if (this.state.activeCategory !== null ) {
                                            if (this.state.activeCategory !== post.CategoryId) {
                                                return null;
                                            }
                                        } 
                                        
                                        return <PostCard key={id}
                                            category={post.CategoryName}
                                            subcategory={post.SubcategoryName}
                                            title={post.Title}
                                            creatorId={post.UserId}
                                            creatorPhotoId={post.Uid}
                                            creatorName={post.UserName}
                                            postId={post.Id}
                                            handleClick={() => this.props.history.push('/contents/' + post.Address + '/')}
                                        />
                                    })
                                }
                            </div>
                            <br/>
                            {
                                this.state.latestPosts.length > 4 ?
                                    !this.state.latestPostsLoaded ?
                                        <button onClick={this.loadLatestPosts} className="button-secondary">Devamını Göster</button> 
                                    : <button id="latest-posts-button" onClick={this.clearFilter} className="button-secondary">{ this.state.activeFilter === null ? 'Tümünü Görüntüle' : 'Geri Dön'}</button>
                                : null
                            }
                        </div>
                        : 
                        null
                    }
                    

                    <br/>
                    
                    {
                        this.state.activeFilter === null || this.state.activeFilter === 'popular-posts' ?
                            <h3>EN POPÜLER İÇERİKLER</h3>
                        : null
                    }

                    {
                        this.state.activeFilter === null || this.state.activeFilter === 'popular-posts' ?
                            <div id="popular-posts" className="grid-container">
                                <div>
                                    {
                                        this.state.popularPosts.slice(0, this.state.popularPostsPageSize).map((post, id) => {
                                            if (this.state.activeCategory !== null ) {
                                                if (this.state.activeCategory !== post.CategoryId) {
                                                    return null;
                                                }
                                            } 

                                            return <PostCard key={id}
                                                category={post.CategoryName}
                                                subcategory={post.SubcategoryName}
                                                title={post.Title}
                                                creatorId={post.UserId}
                                                creatorPhotoId={post.Uid}
                                                creatorName={post.UserName}
                                                postId={post.Id}
                                                handleClick={() => this.props.history.push('/contents/' + post.Address + '/')}
                                            />
                                        })
                                    }
                                </div>
                                <br/>
                                {
                                    this.state.popularPosts.length > 4 ?
                                        !this.state.popularPostsLoaded ?
                                            <button onClick={this.loadPopularPosts} className="button-secondary">Devamını Göster</button> 
                                        : <button id="popular-posts-button" onClick={this.clearFilter} className="button-secondary">{ this.state.activeFilter === null ? 'Tümünü Görüntüle' : 'Geri Dön'}</button>
                                    : null
                                }
                            </div>
                        : null
                    }
                    
                </div>

                <Footer />
            </motion.div>
        )
    }
}

const mapStateToProps = (state) => ({
    category: state.category.categories,
    alert: state.alert
});

export default compose(
    withRouter,
    connect(mapStateToProps))(ContentsPage);