import React, { Component } from 'react'
import SearchBox from '../components/form-elements/SearchBox';
import blogtracerLogo from '../assets/images/logo.svg' 
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class Navigation extends Component {
    state = {
        searchBoxVisibility: false,
        searchTo: '/search'
    }

    handleClick = (event) => {
        event.preventDefault();
        const {searchBoxVisibility} = this.state;

        this.setState({
            searchBoxVisibility: !searchBoxVisibility,
            searchTo: '/search/' + document.getElementById('searchbox').value
        })    
        document.getElementById('nav-search').style.left = !searchBoxVisibility ? "calc(25% + 20px)" : "0";
        document.getElementById('searchbox').style.display = !searchBoxVisibility ? "block" : "none"; 
        document.getElementById('searchbox').focus();
    }

    render() {
        const {currentPage} = this.props;
        const mainSection = <React.Fragment>
            <li className={currentPage === "HomePage" ? "active" : null}>
                <Link to={this.props.user.loggedIn ? '/' : '/login'}>
                    <i className="far fa-home"></i>
                </Link>
            </li>
            {
                this.props.user.loggedIn ?
                <React.Fragment>
                    <li className={currentPage === "LibraryPage" ? "active" : null}>
                        <Link to="/contents">
                            <i className="far fa-books"></i>
                        </Link>
                    </li>
                    <li className={currentPage === "FollowingPage" ? "active" : null}>
                        <Link to="/followed">
                            <i className="far fa-star"></i>
                        </Link>
                    </li>
                    
                </React.Fragment>
                : null
            }
        </React.Fragment>;
        
        const accountSection = <div className="navigation-account">
            <li>
                <Link to="/login">
                    <i className="far fa-sign-in"></i> <span>Giriş Yap</span>
                </Link>
            </li>
            <li>
                <Link to="/signup">
                    <i className="far fa-user-plus"></i> <span>Kayıt Ol</span>
                </Link>
            </li>
        </div>;
        
        return (<React.Fragment>
            <div id="navigation" className="box">
                <ul id="navigation-button">
                    {/* LOGO */}
                    <li>
                        <div className="navigation-logo">
                            <img src={blogtracerLogo} alt=""/> 
                        </div>
                    </li>
                    <li>
                        <div className="navigation-splitter"></div>
                    </li>
                    {/* MENU */}
                    { mainSection }
                    {/* SEARCHBOX */}
                    {
                        this.props.user.loggedIn ?
                            <React.Fragment>
                                <li>
                                    <div className="navigation-splitter"></div>
                                </li>
                                <li id="nav-search">
                                    <Link to="/" onClick={this.handleClick}>
                                        <i className="far fa-search"></i>
                                    </Link>
                                </li>
                                <SearchBox searchBoxId="searchbox" handle={this.handleClick}/>
                            </React.Fragment> : null
                    }
                    
                    {!this.props.user.loggedIn ? accountSection : null }
                </ul>
            </div>
            {
                this.props.user.loggedIn ?
                <div className="mobile-nav box">
                    <ul id="navigation-button">
                        { mainSection }
                        <li>
                            <Link className={currentPage === "ProfilePage" ? "active" : null} to={'/users/' + this.props.user.username}>
                                <i className="far fa-user"></i>
                            </Link>
                        </li> 
                    </ul>
                </div> : null
            }
            
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(Navigation);
