const initialState = {
    type: "",
    message: "",
    visibility: false,
    intervalID: null
}

function alertReducer(state = initialState, action) {
    switch (action.type) {
        case "CHANGE_VISIBILITY":
        return {
            ...state, visibility: action.payload
        };
        case "CHANGE_ALERT_TYPE":
        return {
            ...state, type: action.payload
        };
        case "SET_INTERVAL":
        return {
            ...state, intervalID: action.payload
        };
        case "SET_MESSAGE":
        return {
            ...state, message: action.payload
        };
        default:
        return state;
    }
}
export default alertReducer;