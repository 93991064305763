import {combineReducers } from 'redux';
import alertReducer from './AlertReducer';
import userReducer from './UserReducer';
import categoryReducer from './CategoryReducer';
import contentReducer from './ContentReducer';

const rootReducer = combineReducers({
    user: userReducer,
    category: categoryReducer,
    alert: alertReducer,
    content: contentReducer
});

export default rootReducer;