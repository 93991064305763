import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Logout } from '../services/UserService'; 
import userNullPhoto from '../assets/images/user.png'; 
import { showAlert } from '../services/Alert';
import { motion } from 'framer-motion';

class SideNavigation extends Component {
    logOut = () => {
        Logout();
        showAlert("success","Görüşmek üzere " + this.props.user.fullName + "!", this);
        this.props.dispatch({type:"FULLNAME", payload: ''});
        this.props.dispatch({type:"USERID", payload: ''});
        this.props.dispatch({type:"LOGGED_IN", payload: false});
    }

    messageBox = () => {
        showAlert("error","Hata! Mesaj kutusu özelliği henüz aktif değil.", this);
    }

    render() {
        const { userPhoto, fullName, role } = this.props.user;
        let userRole;

        switch (role) {
            case 'admin':
                userRole = 'Yönetici';
                break;
            case 'content-creator':
                userRole = 'İçerik Üreticisi';
                break;
            case 'guest-user':
                userRole = 'Misafir Kullanıcı';
                break;
            default:
                userRole = 'Kullanıcı';
                break;
        }

        return (
            <motion.div 
            id="side-navigation" className="box"
            exit={{ opacity: 0, transition: { duration: 0, delay: 0 } }}>

                <div id="side-navigation-photo">
                    <img alt="" src={userPhoto !== "" && userPhoto !== null &&  userPhoto !== undefined ? userPhoto : userNullPhoto} />
                </div>
                <div id="side-navigation-main">
                    <div>
                        <span><b>{fullName}</b></span>
                        <br/>
                        <span>{userRole}</span>
                    </div>
                    <ul>
                        <h4> HESABIM</h4>
                        <li><Link to={'/users/' + this.props.user.username + '/liked'}><i className="far fa-heart"></i> Beğenilenler</Link></li>
                        <li><Link to={'/users/' + this.props.user.username + '/saved'}><i className="far fa-bookmark"></i> Kaydedilenler</Link></li>
                        <li><Link to={'/users/' + this.props.user.username + '/statistics'}><i className="far fa-chart-bar"></i> İstatistikler</Link></li>
                        <li onClick ={this.messageBox}><Link to="/"><i className="far fa-envelope"></i> Posta Kutusu</Link></li>
                        <h4> YÖNETİM</h4>
                        <li><Link to="/contents/create"><i className="far fa-plus-square"></i> İçerik Oluştur</Link></li>
                        <li><Link onClick={this.logOut} to="/"><i className="far fa-sign-out-alt"></i> Çıkış Yap</Link></li>
                        {/* <li><Link to="/contents/edit"><i className="far fa-user-cog"></i> Profili Düzenle</Link></li> */}
                    </ul>
                </div>

            </motion.div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    alert: state.alert
});

export default connect(mapStateToProps)(SideNavigation);