import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from "redux"; 
import { withRouter } from 'react-router';
import { showAlert } from '../services/Alert';
import AccessAPI  from '../services/AccessAPI';
import InvertedTextBox from '../components/form-elements/InvertedTextBox';
import { motion } from 'framer-motion';

class RegisterPage extends Component {
    // STATE
    state = {
        currentLevel : 0,
        processLevel : {
            level1 : "NAME",
            level2 : "EMAIL",
            level3 : "USERNAME&PASSWORD"
        },
        name : "",
        username : "",
        email : "",
        password : "",
        passwordVerification : "",
    }

    // PROCCESS ++
    handleIncrease = (e) => {
        e.preventDefault();
        if (this.state.currentLevel === 0) {
            const name = document.getElementById("name").value;
            if (name.trim() !== "") {
                if (name.length >  1) {
                    this.setState({
                        currentLevel: this.state.currentLevel+1,
                        name : name
                    });
                } else {
                    showAlert("error","Hata! Eksik giriş yaptınız.", this);
                }
            } else {
                showAlert("error","Hata! Lütfen adınızı girin.", this);
            }
        } 
        else if (this.state.currentLevel === 1) {
            const emailAddress = document.getElementById("email").value;
            
            if (emailAddress.trim() !== "" && emailAddress.indexOf('@') !==  -1) {
                this.setState({
                    currentLevel: this.state.currentLevel+1,
                    email: emailAddress
                });
            } else {
                showAlert("error","Hata! Lütfen mail adresinizi girin.", this);
            }
        }
    }

    // PROCCESS --
    handleDecrease = (e) => {
        e.preventDefault();
        this.setState({currentLevel: this.state.currentLevel-1});
    }

    // REGISTER 
    register = async (e) => {
        e.preventDefault();
        const username = document.getElementById("userId").value;
        const password = document.getElementById("password").value;
        const passwordVerification = document.getElementById("passwordVerification").value;

        if (username.trim() !== "" && password.trim() !== "" && passwordVerification.trim() !== "") {
            const req = await AccessAPI('/api/users/is-registered/' + username);
            if (!req.success) {
                if (password === passwordVerification) {
                    if (password.length > 5 && username.length > 3) {
                        const reqx = await AccessAPI("/api/users/create",{
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                    name: this.state.name,
                                    username: username,
                                    email: this.state.email,
                                    password: password
                            })
                        });
                        if (reqx.success) {
                            this.props.history.push('/login');
                            showAlert("success","Kayıt tamamlandı. Aramıza hoşgeldin " + this.state.name + ".", this);
                        } else {
                            showAlert("error","Hata! Kayıt tamamlanamadı.", this);
                        }
                    } else {
                    showAlert("error","Hata! Şifre en az 6, kullanıcı adı ise en az 4 karakterden oluşmalıdır.", this);
                    }
                } else {
                    showAlert("error","Hata! Şifre doğrulanamadı, şifrelerinizin eşleştiğinden emin olun.", this);
                }
            } else {
                showAlert("error","Hata! Bu kullanıcı adı zaten kullanılıyor.", this);
            }
        } else {
            console.log(password);
            showAlert("error","Hata! Tüm alanları eksiksiz doldurmalısın.", this);
        }


        
    }

    render() {
        const {currentLevel} = this.state;
        let  activeButtons;

        switch (currentLevel) {
            case 0:
                activeButtons = <button className="button-primary" onClick={this.handleIncrease}>Devam Et</button>;
                break;
            case 1:
                activeButtons = <div>
                    <button className="button-primary" onClick={this.handleIncrease}>Devam Et</button>
                    <button className="button-primary" onClick={this.handleDecrease}>Geri Git</button>
                </div>;
                break;
            case 2:
                activeButtons = <div>
                    <button className="button-primary" onClick={this.register}>Hesap Oluştur</button>
                    <button className="button-primary" onClick={this.handleDecrease}>Geri Git</button>
                </div>;
                break;
            default:
                break;
        }

        return (
            <motion.div id="register-form" className="box"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, top: [-10, 10, 8, 10, 0], transition: { delay: 0.1} }}
            exit={{ opacity: 0, transition: { duration: 0, delay: 0 } }}>

                <div id="form-logo"></div>
                <br/>
                <form>
                    {currentLevel === 0 ? <InvertedTextBox inputID="name" title="Ad" placeHolder="Adınızı girin"/> : null}
                    {currentLevel === 1 ? <InvertedTextBox inputID="email" title="Email" placeHolder="Email adresinizi girin"/> : null}
                    {currentLevel === 2 ? <div>
                            <InvertedTextBox inputID="userId" title="Kullanıcı Adı" placeHolder="Kullanıcı adınızı girin"/>
                            <InvertedTextBox type="password" inputID="password" title="Şifre" placeHolder="Şifrenizi girin"/>
                            <InvertedTextBox type="password" inputID="passwordVerification" title="Şifre Doğrulama" placeHolder="Şifrenizi tekrar girin"/>
                        </div> : null}
                    {activeButtons}     
                    <br/>
                    <Link to="/login" className="link">Giriş Yap</Link>
                </form>
            </motion.div>
        )
    }
}

const mapStateToProps = (state) => ({
    alert: state.alert
});

export default compose(
    withRouter,
    connect(mapStateToProps))(RegisterPage);