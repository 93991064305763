import { motion } from 'framer-motion';
import React, { Component } from 'react'
import { connect } from "react-redux";

class Alert extends Component {
    componentDidMount() {
        var intervalId = setInterval(() => {
            this.props.dispatch({type:"CHANGE_VISIBILITY", payload:false});
        }, 7000);
        this.props.dispatch({type:"SET_INTERVAL", payload:intervalId});
    }
  
    componentWillUnmount() {
        clearInterval(this.props.intervalId);
    }

    render() {
        const {visibility, type, message} = this.props;
        return (
            visibility ?
            <motion.div className={"alert alert-"+type} 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0 }}}
            exit={{ opacity: 0 }}>

                <p>{message}</p>
            </motion.div> : ""
        )
    }
}

export function showAlert(type, message, e) {
    e.props.dispatch({type:"CHANGE_ALERT_TYPE", payload:type});
    e.props.dispatch({type:"SET_MESSAGE", payload:message})
    e.props.dispatch({type:"CHANGE_VISIBILITY", payload:true});
}

export function setAlert(type, message, props) {
    props.dispatch({type:"CHANGE_ALERT_TYPE", payload:type});
    props.dispatch({type:"SET_MESSAGE", payload:message})
    props.dispatch({type:"CHANGE_VISIBILITY", payload:true});
}

const mapStateToProps = (state) => ({
    type: state.alert.type,
    message: state.alert.message,
    visibility: state.alert.visibility,
    intervalId: state.alert.intervalId
});

export default connect(mapStateToProps)(Alert);





