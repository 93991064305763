import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion';
import AccessAPI from '../services/AccessAPI';
import userNullPhoto from '../assets/images/user.png'; 

export default class PostCard extends Component {
    state = {
        likes: 0,
        views: 0,
        contentImage: null,
        userImage: null,
        isContentImageLoaded: false,
        isUserImageLoaded: false
    }

    updateData = async () => {
        const token = localStorage.getItem('token');
        const postId = this.props.postId;

        if (postId !== undefined) {
            const viewsData = await AccessAPI('/api/' + token + '/contents/' + postId + '/views');
            const likesData = await AccessAPI('/api/' + token + '/contents/' + postId + '/likes');
    
            this.setState({
                views: viewsData.views,
                likes: likesData.likes
            });

            if (!this.state.isContentImageLoaded) {
                const imageData = await AccessAPI('/api/' + token + '/contents/' + postId + '/image');
                if (imageData.success) {
                    this.setState({
                        contentImage: imageData.image,
                        isContentImageLoaded: true
                    });
                }
            }

            if (!this.state.isUserImageLoaded) {
                const imageData = await AccessAPI('/api/' + token + '/users/' + this.props.creatorPhotoId + '/image');
                if (imageData.success) {
                    this.setState({
                        userImage: imageData.image,
                        isUserImageLoaded: true
                    });
                } 
            }
        }
    }

    componentDidMount = async () => {
        this.updateData();
    }

    render() { 
        return (
            <motion.div
              animate={{ x: [-3, 0], opacity: [0.5, 1] }} 
              transition={{ duration: 0.8 }}
              exit={{ opacity: 0 }}
              className="grid-item">

                <div className="content">
                    <div className="content-image" onClick={this.props.handleClick}>
                        <img alt="" src={this.state.contentImage} /><br/>
                    </div>
                    <div>
                        <h4 onClick={this.props.handleClick}>{this.props.category}</h4>
                        <h3 onClick={this.props.handleClick}>{this.props.title}</h3>
                        <div className="blogcard-bottom">
                            <div>
                                <img alt="" src={this.state.userImage !== null ? this.state.userImage : userNullPhoto} />
                                <Link to={'/users/'+this.props.creatorId+'/'}>
                                    {this.props.creatorName}
                                </Link>
                            </div>
                            <div className="blogcard-statistics">
                                {/* <div><i class="fas fa-comment"></i> 180</div> */}
                                <div><i className="fas fa-eye"></i> {this.state.views}</div>
                                <div><i className="fas fa-heart"></i> {this.state.likes}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        )
    }
}