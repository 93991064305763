import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from "redux"; 
import { withRouter } from 'react-router-dom';
import PostCard from '../components/PostCard';
import { motion } from 'framer-motion';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import AccessAPI from '../services/AccessAPI';
import { Bar } from 'react-chartjs-2';
import { showAlert } from '../services/Alert';
import userNullPhoto from '../assets/images/user.png'; 

class ProfilePage extends Component {
    state = {
       selectedMenu: 0,  
       user: null,
       userData: {},
       posts: [],
       contentList: [],
       likedList: [],
       savedList: [],
       contentsLoaded: false,
       followed: false,
       defaultFollowState: false,
       statisticData: {
        labels: ['4 Ay Önce', '3 Ay Önce', '2 Ay Önce', 'Geçen Ay', 'Bu Ay'],
        datasets: [
          {
            label: '# Görüntülenme',
            data: [0, 0, 0, 0, 0, 0],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
       },
       statisticOptions: {}
    };

    menuHandleClick = (index) => {
        this.setState({
            selectedMenu: index
        });
    }

    loadUserData = async () => {
        const token = localStorage.getItem('token');
        const username = this.props.user;

        // USER DATA 
        const userIdReq = await AccessAPI('/api/users/is-registered/' + username);
        if (userIdReq.success) {
            const userDataReq = await AccessAPI('/api/' + token + '/users/' + userIdReq.userId );
            if (userDataReq.success) {
                let newData = userDataReq;
                newData.id = userIdReq.userId;
                this.setState({
                    userData: newData
                });
            }
        }

        // USER CONTENTS
        const userContentsReq = await AccessAPI('/api/' + token +  '/users/'+ username +'/contents');
        if (userContentsReq.success) {
            let list = [];

            this.addPost(userContentsReq.posts);

            userContentsReq.posts.map((post) => {
                list.push(post.Id);
                return null;
            });

            if (list.length > 0) {
                this.setState({
                    contentList: list
                });
            }
        }

        // USER LIKED CONTENTS
        const userLikedContentsReq = await AccessAPI('/api/' + token +  '/users/'+ username +'/liked');
        if (userLikedContentsReq.success) {
            let list = [];

            this.addPost(userLikedContentsReq.posts);

            userLikedContentsReq.posts.map((post) => {
                list.push(post.Id);
                return null;
            });

            if (list.length > 0) {
                this.setState({
                    likedList: list
                });
            }
        }

        // USER SAVED CONTENTS
        const userSavedContentsReq = await AccessAPI('/api/contents/saved/',{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userId: this.props.userData.id
            })
        }); 
        if (userSavedContentsReq.success) {
            let list = [];

            userSavedContentsReq.data.map(post => {
                list.push(post.PostId)
                return null;
            });

            if (list.length > 0 ){
                this.setState({
                    savedList: list
                });
            }
        }

        // GET STATISTICS
        const statisticsReq = await AccessAPI('/api/' + token +  '/users/'+ username +'/statistics');
        if (statisticsReq.success) {
            let currentDatetimeData = new Date();
            currentDatetimeData = currentDatetimeData.toLocaleDateString().split('.');  
            let arr = [0, 0, 0, 0, 0]
            statisticsReq.views.map(datetime => {
                let viewData = datetime.split(',')[0].split('/');

                if (viewData[2] === currentDatetimeData[2]) {
                    if (parseInt(viewData[0]) === parseInt(currentDatetimeData[1])) {
                        arr[4] += 1;
                    } else if ((parseInt(viewData[0]) + 1) === parseInt(currentDatetimeData[1])) {
                        arr[3] += 1;
                    } else if ((parseInt(viewData[0]) + 2) === parseInt(currentDatetimeData[1])) {
                        arr[2] += 1;
                    } else if ((parseInt(viewData[0]) + 3) === parseInt(currentDatetimeData[1])) {
                        arr[1] += 1;
                    } else if ((parseInt(viewData[0]) + 4) === parseInt(currentDatetimeData[1])) {
                        arr[0] += 1;
                    }
                }
                return null;
            });

            let newState = this.state.statisticData;
            newState.datasets[0].data = arr;
            this.setState({
                statisticData: newState 
            }); 
        }
    }

    addPost = (arr) => {
        arr.map((post) => {
            if (!this.state.posts.find((w) => w.Id === post.Id)) {
                let posts = this.state.posts;
                posts.push(post);
                
                this.setState({
                    posts
                });     
            } 
            return null;
        })
    }

    componentDidMount = async () => {
        this.loadUserData();

        if (this.props.menu !== undefined) {
            setTimeout(function(){
                this.setState({ selectedMenu: this.props.menu });
            }.bind(this), 3000);
        }
        
        await setTimeout(async function(){
            if (this.state.userData.id === this.props.userData.id) {
                if (this.props.history.location.pathname.split('/')[1] === 'users' && this.props.history.location.pathname.split('/')[2] === this.props.userData.username) {
                    let wrapperElem = document.getElementsByClassName('content-wrapper');
                    wrapperElem[0].classList.add('full-size');
                    document.getElementById('side-navigation').style.display = 'none';
                }
            } else {
                // IS FOLLOWED USER
                const followedReq = await AccessAPI('/api/' + localStorage.getItem('token') + '/users/' + this.props.userData.id + '/followed-users/');
                if (followedReq.success) {
                    followedReq.followedUsers.map((user) => {
                        if (user === this.state.userData.id) {
                            this.setState({
                                followed: true,
                                defaultFollowState: true
                            });
                        }
                        return null;
                    });
                } 
            }
        }.bind(this), 3000);

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedMenu !== this.state.selectedMenu) {
            if (document.getElementsByClassName('statistics').length > 0) {
                
                this.setState({
                    statisticOptions: {}
                });

                setTimeout(function(){
                    this.setState({
                        statisticOptions: {animation: {duration: 0}}
                    });
                }.bind(this), 1000);
            } 
        }
    }

    componentWillUnmount = async () => {
        const userId = this.props.userData.id;

        // Follow State
        if (this.state.defaultFollowState !== this.state.followed) {
            const followedUserId = this.state.userData.id;
            if (this.state.followed) {
                await AccessAPI('/api/users/followed-users/create',{
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        userId: userId,
                        followedUserId: followedUserId 
                    })
                });
            } else {
                await AccessAPI('/api/' + localStorage.getItem('token')  + '/users/' + userId + '/followed-users/' + followedUserId,{
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: null
                });
            }
        } 
    }

    editProfile = () => {
        showAlert("error","Hata! Profil düzenleme işlemi yapılamıyor.", this);
    }

    changeFollowState = () => {
        this.setState({
            followed: !this.state.followed
        });
    }
    
    renderContents = (data) => {
        let arr = [];

        if (data !== undefined) {
            data.map(data => {
                if (this.state.posts.find(w => w.Id === data)) {
                    const post = this.state.posts.find(p => p.Id === data);
                    arr.push(<PostCard key={post.Id}
                        category={post.CategoryName}
                        subcategory={post.SubcategoryName}
                        title={post.Title}
                        creatorId={post.UserId}
                        creatorPhotoId={post.Uid}
                        creatorName={post.UserName}
                        postId={post.Id}
                        handleClick={() => this.props.history.push('/contents/' + post.Address + '/')}
                    />);
                }
                return null;
            });
        }
        return arr;
    } 

    
    render() {
        let menu = [
            "İÇERİKLER",
            "BEĞENİLENLER"
        ];


        let menuList = {};

        menuList[0] = this.state.contentList;
        menuList[1] = this.state.likedList;

        if (this.state.userData.id === this.props.userData.id) {
            menu.splice(2, 0, "KAYDEDİLENLER");
            menuList[2] = this.state.savedList;
        }

        const statisticsMenu = "İSTATİSTİKLER"; 
        menu.push(statisticsMenu);

        let userRole;
        switch (this.state.userData.role) {
            case 'admin':
                userRole = 'Yönetici';
                break;
            case 'content-creator':
                userRole = 'İçerik Üreticisi';
                break;
            case 'guest-user':
                userRole = 'Misafir Kullanıcı';
                break;
            default:
                userRole = 'Kullanıcı';
                break;
        }

        const isFollowed = this.state.followed;


        return (
            <motion.div className={'content-wrapper'}
            // full-size
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1} }}
            exit={{ opacity: 0, transition: { duration: 0, delay: 0 } }}>
                
                <div id="profile-page" className="box">
                    <div className="profile-card">
                        <div className="profile-photo">
                            <img alt="" src={this.state.userData.photo !== null && this.state.userData.photo !== '' &&  this.state.userData.photo !== undefined ? this.state.userData.photo : userNullPhoto } />
                            {
                                this.state.userData.id === this.props.userData.id ?
                                    <button onClick={this.editProfile} className="button-secondary">Düzenle</button>
                                : isFollowed ? 
                                    <motion.button 
                                    whileHover={{ scale: 1.05 }}
                                    onClick={this.changeFollowState}
                                    className="button-secondary">Takip <i className='far fa-check'></i></motion.button>
                                    : 
                                    <motion.button 
                                    whileHover={{ scale: 1.05 }}
                                    onClick={this.changeFollowState}
                                    className="button-secondary">Takip Et</motion.button>
                            }
                            
                        </div>
                        <div className="profile">
                            <h3>{this.state.userData.name} <i className="far fa-badge-check"></i></h3>
                            <h4>{userRole}</h4>
                            <p>{this.state.userData.profile}</p>
                            <div className="social">
                                <Link className="link" to="/"><i className="far fa-paper-plane"></i> {this.state.userData.username}@blogtracer.com</Link><br/>
                                { 
                                   this.state.userData.website !== null ?                                   
                                        <Link className="link" to="/"><i className="far fa-browser"></i> {this.state.userData.website}</Link>
                                   : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="menu">
                        {menu.map((name, index) => (  
                            <button key={index} onClick={() => this.menuHandleClick(index)} className={index === this.state.selectedMenu ? "active" : null}>  
                                {name}  
                            </button>  
                        ))}  
                    </div>
                    {
                        this.state.selectedMenu !== 4 ?
                            <div className="grid-container">
                                <div>
                                    { 
                                        this.renderContents(menuList[this.state.selectedMenu])
                                    }
                                </div>
                            </div>
                        : null
                    }
                    

                    {
                        menu[this.state.selectedMenu] === statisticsMenu ?
                            <div className="statistics">
                                {/* <i className="rotate fas fa-spinner-third"></i>      */}
                                <Bar data={this.state.statisticData} width={100} height={50} options={this.state.statisticOptions} />                                                
                            </div>
                        : null
                    }
                </div>
                <Footer />
            </motion.div>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.user,
    alert: state.alert
});

export default compose(
    withRouter,
    connect(mapStateToProps))(ProfilePage);